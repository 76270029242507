import { defineStore } from "pinia";
export const useTrailerStore = defineStore("trailer", {
  state: () => ({
    transitionState: "banner",
    trailerStarted: false,
    trailerPlayinfo: null,
  }),
  getters: {
    isTrailerPlaying() {
      return this.transitionState === "trailer";
    },
  },
  actions: {
    startTrailer() {
      this.transitionState = "trailer";
    },
  },
});
